#top-header {
  background-color: #bcbcbc;
  font-size: 17px;
  text-align: center;
  color: white;
  margin-bottom: 30px;
}

body {
  color: #6f6e73;
  font-size: 16px;
}

header img {
  max-width: 100%;
}
header li a,
.navbar-light .navbar-nav .nav-link {
  color: #a1288d;
  text-decoration: none;
  font-weight: 500;
}
header li {
  list-style: none;
  display: inline-block;
  max-width: 20%;
  padding: 0 10px;
  vertical-align: top;
  text-align: center;
  font-size: 16px;
  color: #a1288d;
  vertical-align: middle;
}
header ul {
  padding: 0;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #f8a137;
}
header nav {
  margin-top: 20px;
  text-align: center;
}
header {
  padding-bottom: 50px;
}

header .external a,
.navbar-light .navbar-nav .nav-link.external {
  color: #a1288d;
}

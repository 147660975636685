.overlay,
#overlay {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(255, 255, 255); /* Black fallback color */
  background-color: rgba(255, 255, 255, 0.7); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  display: none;
  z-index: 10;
}

.ref_bibliograficas {
  background-color: #a2278c;
  color: white;
  font-size: 30px;
  letter-spacing: -1px;
  padding: 50px 0;
}

.ref_bibliograficas_holder {
  position: relative;
}

.plus_icon {
  position: absolute;
  right: 0;
}
footer {
  padding-top: 40px;
}
footer img {
  max-width: 100%;
}

footer nav {
  margin-top: 60px;
}
footer nav ul {
  margin: 0;
  padding: 0;
}
footer nav li {
  list-style: none;
  text-align: center;
  color: #a2278c;
}
footer nav li a {
  color: #a2278c;
  text-decoration: none;
  cursor: pointer;
}
footer .signiture {
  font-size: 14px;
  margin: 0;
  text-align: center;
}

footer p {
}

/*
* BIBLIOGRAFIA
*/

.overlay .bibliografia {
  background-color: #a3258e;
  color: white;
  max-width: 60%;
  margin: 0 auto;
  padding: 50px 65px;
  font-size: 14px;
  line-height: 24px;
  margin-top: 7%;
  display: none;
  position: relative;
}

.overlay .closebtn img {
  max-width: 100%;
}
.overlay .closebtn {
  max-width: 40px;
  height: auto;
  position: absolute;
  right: 50px;
  z-index: 99;
  cursor: pointer;
}

.overlay .bibliografia a {
  text-decoration: none;
  color: white;
}

#refluxo-gastroesofagico {
  background-size: cover;
  padding: 90px 0;
  color: white;
}

#refluxo-gastroesofagico img,
#refluxo-white-section img {
  max-width: 100%;
}

#refluxo-gastroesofagico h1 {
  font-weight: bold;
}

#refluxo-gastroesofagico img {
  max-width: 90%;
}
#refluxo-gastroesofagico p {
  font-size: 24px;
}

#refluxo-white-section {
  padding: 100px 0;
  font-size: 24px;
}

#refluxo-white-section h2 {
  font-size: 35px;
  color: #99cb54;
}

#refluxo-white-section h3 {
  margin-top: 60px;
  font-size: 30px;
  color: #99cb54;
}
#refluxo-white-section h4 {
  font-size: 26px;
  color: #99cb54;
}

#refluxo-white-section ul {
  list-style: none; /* Remove default bullets */
}

#refluxo-white-section ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #99cb54; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
#refluxo-white-section .spacer {
  margin-top: 80px;
}
.green_bold {
  font-weight: bold;
  color: #99cb54;
}

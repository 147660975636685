#esofago {
  background-size: cover;
  padding: 90px 0;
  color: white;
}

#esofago h1 {
  font-weight: bold;
}
#esofago p {
  font-size: 24px;
}

#esofago img {
  max-width: 90%;
}

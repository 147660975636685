#conselhos-praticos {
  background-size: cover;
  padding: 90px 0;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

#conselhos-praticos h2 {
  font-weight: bold;
}

#conselhos-praticos img {
  max-width: 90%;
}

#conselhos-praticos .conselhos_praticos {
  list-style: none;
  margin: 0;
  padding: 0;
}
#conselhos-praticos .conselhos_praticos li {
  margin-bottom: 25px;
  font-weight: bold;
}

#conselhos-praticos .conselhos_praticos li .pdf_icon {
  margin-right: 10px;
  max-width: 35px;
}

#conselhos-praticos .conselhos_praticos a {
  cursor: pointer;
  color: white;
}

#overlay-conselho {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(255, 255, 255); /* Black fallback color */
  background-color: rgba(255, 255, 255, 0.7); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  display: none;
  z-index: 10;
}

#conselho .col-md-5 img {
  max-width: 90%;
}

#conselho img {
  max-width: 100%;
}
#conselho img.download_btn {
  max-width: 80%;
  align-items: center;
  margin-top: 15px;
}

#conselho {
  background-color: white;
  max-width: 70%;
  margin: 0 auto;
  padding: 50px 65px;
  font-size: 14px;
  line-height: 24px;
  margin-top: 4%;
  position: relative;
}

.conselho_legend {
}

#conselho #alimentacao,
#conselho #exercicio,
#conselho #sono {
  display: none;
}

#conselho .closebtn img {
  max-width: 100%;
}
#conselho .closebtn {
  max-width: 40px;
  height: auto;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
  cursor: pointer;
}

#politica_cookies {
  background-color: #99cb54;
  color: white;
  font-weight: 24px;
  padding: 120px 0;
}
#politica_cookies img {
  max-width: 100%;
}

#politica_cookies h4 {
  font-weight: bold;
  margin-top: 45px;
}

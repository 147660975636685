#esoxx-container {
  font-size: 24px;
}

#esoxx-container .purple-bar {
  background-color: #a3258e;
  color: white;
  text-align: center;
  padding: 20px 0;
}

#esoxx-container img {
  max-width: 100%;
}
#esoxx-area1 {
  padding-top: 125px;
}
#esoxx-area1 h2 {
  font-size: 40px;
  font-weight: bold;
  color: #a1288d;
}

#esoxx-area1 h3 {
  font-weight: bold;
  font-size: 30px;
  color: #f8991d;
  margin-bottom: 60px;
}
#esoxx-area1 p {
  margin-bottom: 50px;
}
#esoxx-area1 p.purple-txt {
  color: #a1288d;
  font-size: 28px;
}

#esoxx-area2 {
  background-position: right top;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  padding: 100px 0;
}

#esoxx-area2 h2 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 50px;
  color: #a1288d;
}

#esoxx-area2 h3.accoes {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: left 18px;
  padding-left: 50px;
  background-size: 40px;
}
#esoxx-area2 h3.accoes span {
  font-size: 16px;
  display: block;
}
#esoxx-area2 h3.accao1 {
  background-image: url(../../public/images/bullet_1.png);
  color: #f8991d;
}
#esoxx-area2 h3.accao2 {
  background-image: url(../../public/images/bullet_2.png);
  color: #99cb55;
}
#esoxx-area2 h3.accao3 {
  background-image: url(../../public/images/bullet_3.png);
  color: #a3258e;
}

#video-link {
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
}

#esoxx-area3 {
  padding: 100px 0;
}
#esoxx-area3 h2 {
  font-size: 40px;
  font-weight: bold;
  color: #a1288d;
}

#esoxx-area3 .spacer {
  margin: 70px 0 100px;
}

#esoxx-area3 h3 {
  font-size: 25px;
  color: #f8991d;
  font-weight: bold;
}

#esoxx-area3 img {
  max-width: 90%;
}

#esoxx-area3 li {
  list-style: none;
  background-repeat: no-repeat;
  background-size: contain;
  font-weight: bold;
  padding-left: 50px;
  color: #a1288d;
  margin-top: 15px;
}

#esoxx-area3 h4 {
  font-size: 30px;
  margin-top: 90px;
  font-weight: bold;
  color: #f8991d;
  text-transform: uppercase;
}

#esoxx-container div#video {
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 15;
}

#esoxx-container #video .closebtn {
  position: absolute;
  right: 20px;
  top: 30px;
  max-width: 30px;
  z-index: 999;
}

@font-face {
  font-family: "Futura LT";
  src: url("../public/FuturaLT-Bold.woff2") format("woff2"),
    url("../public/FuturaLT-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura LT";
  src: url("../public/FuturaLT.woff2") format("woff2"),
    url("../public/FuturaLT.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Futura LT" !important;
}

.CookieConsent > div {
  margin: 0 !important;
}

.CookieConsent > div h2,
.CookieConsent > div p {
  padding: 0 20px;
}

.CookieConsent .accordion-item {
  background-color: rgba(255, 255, 255, 0.2);
}

.CookieConsent .accordion-button {
  background-color: transparent;
}
.CookieConsent .accordion-button:focus {
  border: none;
  box-shadow: none;
  color: white;
}
.CookieConsent .accordion-button:not(.collapsed),
.CookieConsent .accordion-button {
  color: white;
  padding-left: 0;
  font-size: 20px;
  font-weight: 500;
}
.CookieConsent .accordion-button::after {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  background-image: none;
}

.CookieConsent .accordion-button.collapsed::after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
